import { Modal } from "react-bootstrap";
import $FIInformation from "../../Constants/FIInformation";
import { DEBIT_CARD_ONLINE_BANKING } from "../../Communication/Constants";
import AppConstants from "../../Constants/AppConstants";
import React, { Component } from "react";
import STRINGS from "../../Constants/Strings";

class BankModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankId: "",
    };
  }

  changeHandler = (event) => {
    if (event.target.name === "bankId") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.bankId !== this.props.bankId) {
      if (!this.props.bankId) {
        this.setState({ bankId: "" });
      }
    }
  }

  continueHandler = () => {
    const { bankId } = this.state;
    const {
      modalType,
      continueHandler,
      email,
      password,
      finInfo,
      setBankId,
    } = this.props;
    if (modalType === "SIGNIN") {
      continueHandler({
        credential: email,
        password,
        bankId,
        bankingType: AppConstants.BANKING_TYPE.PERSONAL,
        checkDuplicate: finInfo?.allowDuplicateProfile,
      });
    } else {
      setBankId(bankId, () => continueHandler(email, bankId));
    }
  };

  render() {
    const { bankId } = this.state;
    const {
      showModal,
      toggleModal,
      finInfo,
      dontHaveBankId,
      errorBankId,
      username,
      password,
      modalType,
      toggleShowBankId,
    } = this.props;
    return (
      <Modal
        show={showModal}
        onHide={toggleModal}
        className={"[ popup-modal__container ]"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static" // this prevents the pop up from being closed when clicked outside of the pop up
        keyboard={false}
      >
        <Modal.Header className="[ popup-modal__header ]" closeButton={false}>
          <Modal.Title>
            {dontHaveBankId ? "Info" : STRINGS.SIGNIN.ERRORS.MODAL_TITLE_INFO}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!dontHaveBankId && (
            <div className="[ form-container ]">
              <div className="[ mb-3 ]">
                <p>
                  {
                    STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL
                      .PROFILE_UNCERTAINTY
                  }
                </p>
              </div>
              <div className="[ mb-3 ]">
                {STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.INSTRUCTION}{" "}
                {finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                  ? STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.LAST_4_DIGITS
                  : `${STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.MEMBER_NUMBER} ${STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.INSTRUCTION1}`}
              </div>
              <div className="[ mb-3 ]">
                <label htmlFor="bankId" className="[ form-label ]">
                  {finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                    ? STRINGS.SIGNIN.FORM.BANK_ID_LABEL_PAN
                    : STRINGS.SIGNIN.FORM.BANK_ID_LABEL_MEMBER_NO}
                </label>
                <input
                  id="bankId"
                  type="text"
                  name="bankId"
                  className={
                    errorBankId
                      ? "[ form-control ] [ is-invalid ]"
                      : "[ form-control ]"
                  }
                  placeholder={
                    finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                      ? STRINGS.SIGNIN.FORM.BANK_ID_LABEL_PAN
                      : STRINGS.SIGNIN.FORM.BANK_ID_LABEL_MEMBER_NO
                  }
                  value={bankId}
                  onChange={this.changeHandler}
                  maxLength={
                    finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                      ? 4
                      : 10
                  }
                  aria-describedby="bankIdHelp"
                />
                {errorBankId && (
                  <div className="invalid-feedback">{errorBankId}</div>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
          <>
            <div className="[ row ]">
              <div className="[ col-6 ]">
                {!dontHaveBankId && (
                  <button
                    type="button"
                    className="[ btn submit-btn ] [ w-100 ]"
                    onClick={() => toggleModal()}
                  >
                    {STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.CANCELBTN}
                  </button>
                )}
              </div>
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className="[ btn submit-btn ] [ w-100 ]"
                  onClick={this.continueHandler}
                >
                  {STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.CONTINUEBTN}
                </button>
              </div>
            </div>
          </>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BankModal;
