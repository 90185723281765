import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import MODALMESSAGES from "Constants/Messages";

import PopupModal from "Components/Common/PopupModal/PopupModal";
import { HTTP_STATUS, S_APPLICANT_ID } from "Communication/Constants";

import { callCreateInverite } from "Components/Inverite/Actions/InveriteAction";
import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";

import Inverite from "Components/Inverite/Inverite";
import BusinessExist from "./BusinessExist";
import { LENDING_PRODUCT } from "../../Communication/Constants";
import { getActiveProduct, getProductInfo } from "../../Utils/CommonUtilities";
import {
  updateProductToVault,
  updateVaultProductList,
} from "../MarketPlace/Action/MarketPlaceAction";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import { getProductTemplate } from "../../Redux/Actions/ProductTemplateActions";

class BusinessExistContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      showModal: false,
      iframeModal: false,
      showRequired: false,
      requiredList: null,
      iframeUrl: "",
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      showVaultLimitError: false,
    };
  }

  componentDidMount() {
    const {
      doGetProductFromVault,
      products,
      processTemplate,
      doGetProductTemplateFlow,
    } = this.props;
    console.log("componentDidMount", products);
    if (products.length <= 0) {
      doGetProductFromVault((response) => {
        if (
          !response ||
          response.data?.length <= 0 ||
          sessionStorage.getItem(S_APPLICANT_ID)
        ) {
          const { history } = this.props;
          history.push(ROUTES.VAULT_DASHBOARD);
        }
      });
    } else {
      const activeProduct = getActiveProduct(products);
      if (!processTemplate || processTemplate?.flow?.length <= 0) {
        doGetProductTemplateFlow(() => {
          console.log("here");
        }, activeProduct);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { products, processTemplate, doGetProductTemplateFlow } = this.props;
    if (prevProps.products !== products && products) {
      const activeProduct = getActiveProduct(products);
      if (!processTemplate || processTemplate?.flow?.length <= 0) {
        doGetProductTemplateFlow(() => {
          console.log("here");
        }, activeProduct);
      }
    }
  }

  showModal = () => {
    this.setState({
      showModal: true,
      modalType: AppConstants.MODALTYPE.INFORMATION,
      modal: {
        title: MODALMESSAGES.EXISTINGMEMBER.SUCCESS_TITLE,
        description: MODALMESSAGES.EXISTINGMEMBER.SUCCESS_MSG,
      },
    });
  };

  gotoMarketplace = () => {
    const { history } = this.props;
    history.push(ROUTES.VAULT_DASHBOARD + "?marketplace");
  };

  handleContinue = (data) => {
    const { continuehandler } = this.props;
    console.log("choice", data);
    continuehandler(data, AppConstants.APPLICATIONSTEP.BUSINESS_EXIST);
  };

  toggleModal = () => {
    this.setState({
      showModal: false,
      iframeModal: false,
      showRequired: false,
    });
  };

  render() {
    const {
      showModal,
      modalType,
      modal,
      iframeModal,
      iframeUrl,
      choice,
      showRequired,
      requiredList,
    } = this.state;
    const { productList, products, getActiveProduct } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        <BusinessExist
          handleContinue={this.handleContinue}
          choice={choice}
          activeProduct={activeProduct}
        />
        {showRequired && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showRequired}
            btnText="Go To Marketplace"
            popupBtnClick={this.gotoMarketplace}
            requiredList={requiredList}
            productList={productList}
            addProduct={this.addProduct}
          />
        )}
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
        {iframeModal && (
          <Inverite
            iframeUrl={iframeUrl}
            iframeModal={iframeModal}
            toggleModal={this.toggleModal}
            showModal={iframeModal}
            popupBtnClick={this.toggleModal}
          />
        )}
      </>
    );
  }
}

BusinessExistContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  doGetAcoountDetails: PropTypes.func.isRequired,
  doCreateInverite: PropTypes.func.isRequired,
  doGetProductFromVault: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  inSessionJointApplicant: PropTypes.object,
  continuehandler: PropTypes.func.isRequired,
};

BusinessExistContainer.defaultProps = {
  history: null,
  products: [],
  inSessionJointApplicant: null,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  productList: state.MarketplaceReducer.productList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  finInfo: state.MarketplaceReducer.finInfo,
  bankingType: state.MarketplaceReducer.bankingType,
  processTemplate: state.ApplicationReducer.processTemplate,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doCreateInverite: (callback) => dispatch(callCreateInverite(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doGetProductTemplateFlow: (callback, product) =>
      dispatch(getProductTemplate(callback, product)),
  };
};

const BusinessExistWrapper = withBusinessHOC(BusinessExistContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessExistWrapper);
