import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PasswordInput } from "Components/Common/PasswordInput/PasswordInput";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import PopupModal from "Components/Common/PopupModal/PopupModal";

import * as ROUTES from "Constants/Routes";
import STRINGS from "Constants/Strings";
import { QuestionCircle } from "react-bootstrap-icons";
import AppConstants from "Constants/AppConstants";
import VALIDATIONS from "Constants/ValidationList";
import { HTTP_STATUS } from "Communication/Constants";

import { usernameCredentials, isDeviceMobile } from "Utils/CommonUtilities";

import checkErrors from "Utils/InputValidator";
import { ArrowRightSquare } from "react-bootstrap-icons";
import MODALMESSAGES from "../../Constants/Messages";
import { capitalize } from "../../Utils/CommonUtilities";
import { DEBIT_CARD_ONLINE_BANKING } from "../../Communication/Constants";

class SignIn extends Component {
  constructor(props) {
    super(props);
    // inputfield error object name  should be inputobject with suffix 'Error'
    //  For example input object: username, input error object: usernameError
    this.state = {
      username: "",
      password: "",
      bankId: "",
      errors: {
        usernameErrorList: [],
        passwordErrorList: [],
        bankIdErrorList: [],
      },
      showPassword: true,
      showModal: false,
      isChecked: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      showReset: false,
    };
    this.userNameInput = React.createRef();
  }

  componentDidMount() {
    const { finInfo } = this.props;
    if (!isDeviceMobile()) {
      this.userNameInput.current.focus();
    }
    if (localStorage.checkbox && localStorage.username !== "") {
      this.setState({
        isChecked: true,
        username: localStorage.username,
      });
    }
    if (finInfo?.sso) {
      this.setState({
        showPassword: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      forgotPasswordState,
      finInfo,
      showReset,
      showPassword,
    } = this.props;
    if (prevProps?.finInfo?.sso !== finInfo?.sso) {
      if (finInfo?.sso) {
        this.setState({
          showPassword: false,
        });
      }
    }
    if (prevProps.forgotPasswordState !== forgotPasswordState) {
      if (
        forgotPasswordState.success &&
        forgotPasswordState.success.status === HTTP_STATUS.OK
      ) {
        if (
          forgotPasswordState.success.data.otpTo ===
          STRINGS.FORGOTPASSWORD.OTP_TO.EMAIL
        ) {
          this.setModalState({
            showModal: true,
            modalType: AppConstants.MODALTYPE.SUCCESS,
            modal: {
              title: STRINGS.FORGOTPASSWORD.MODAL.SUCCESS_MAIL,
              description: "",
            },
          });
        } else {
          this.setModalState({
            showModal: true,
            modalType: AppConstants.MODALTYPE.SUCCESS,
            modal: {
              title: STRINGS.FORGOTPASSWORD.MODAL.SUCCESS_MESSAGE,
              description: "",
            },
          });
        }
      }
    }
    if (!prevProps.showReset && showReset) {
      this.showResetMessage();
    }
    if (!prevProps.showPassword && showPassword) {
      this.setState({ showPassword: true });
    }
  }

  setModalState = (modal) => {
    this.setState(modal);
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  toggleReset = () => {
    const { showReset } = this.state;
    this.setState({
      showModal: !showReset,
      username: "",
      showPassword: false,
    });
  };

  handleBack = () => {
    const { history } = this.props;
    history.push(ROUTES.HOME);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    const { bankingType, doCheckExistingUser } = this.props;
    if (username) {
      const usernameErrors = checkErrors(username, VALIDATIONS.SIGNIN.username);
      console.log(usernameErrors);

      if (usernameErrors?.length === 0) {
        const existingUser = {
          credential: username,
          bankingType,
        };

        doCheckExistingUser(existingUser, (res) => {
          if (
            res.data.autoCreated &&
            res.data.status === "P" &&
            res.data.size === 1
          ) {
            this.processForgotPasswordFLow();
          } else {
            this.continueHandleSubmit();
          }
        });
      } else {
        this.continueHandleSubmit();
      }
    } else {
      this.continueHandleSubmit(e);
    }
  };

  continueHandleSubmit = (e) => {
    const { username, password, bankId, isChecked } = this.state;
    const { handleSignin, bankingType, finInfo } = this.props;
    const errors = this.verifyData();
    console.log("errors", errors);
    if (!errors) {
      const userDetails = {
        credential: usernameCredentials(username),
        password,
        isChecked,
        bankingType,
        bankId,
        checkDuplicate: finInfo?.allowDuplicateProfile,
      };
      handleSignin(userDetails);
    }
  };

  handleChecked = () => {
    const { isChecked } = this.state;

    this.setState({
      isChecked: !isChecked,
    });
  };

  forgotPasswordHandler = () => {
    const { history } = this.props;
    history.push(ROUTES.FORGOT_PASSWORD);
  };

  handleRegistration = () => {
    const { history } = this.props;
    history.push(ROUTES.REGISTER);
  };

  changeHandler = (event) => {
    const { errors } = this.state;
    const { toggleShowBankId, clearBankId } = this.props;
    const errorObject = `${event.target?.name}${STRINGS.COMMON.ERROR_LIST}`;
    const eventTargetName = event.target.name;
    if (eventTargetName === "bankId") {
      const re = /^[0-9\b]+$/;
      if (eventTargetName === "" || re.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
          errors: {
            ...errors,
            [errorObject]: [],
          },
        });
      }
    } else {
      if (event.target.name === "username") {
        console.log("clearing bankId");
        this.setState({ bankId: "" });
        clearBankId();
      }
      this.setState(
        {
          [event.target.name]: event.target.value,
          errors: {
            ...errors,
            [errorObject]: [],
          },
        },
        () => {
          if (eventTargetName !== "bankId") {
            toggleShowBankId(false);
          }
        }
      );
    }
  };

  togglePasswordHandler = () => {
    this.setState((prevState) => {
      return { showPassword: !prevState.showPassword };
    });
  };

  verifyData = () => {
    const { username, password, bankId, errors } = this.state;
    const { finInfo, showBankId } = this.props;
    console.log("verifyData", showBankId);

    const usernameErrors = checkErrors(username, VALIDATIONS.SIGNIN.username);

    const passwordErrors = checkErrors(password, VALIDATIONS.SIGNIN.password);

    let bankIdErrors = [];

    if (showBankId) {
      if (finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING) {
        bankIdErrors = checkErrors(bankId, VALIDATIONS.SIGNIN.bankId_PAN);
      } else {
        bankIdErrors = checkErrors(bankId, VALIDATIONS.SIGNIN.bankId_MEMBER_NO);
      }
    }

    this.setState({
      errors: {
        ...errors,
        usernameErrorList: usernameErrors,
        passwordErrorList: passwordErrors,
        bankIdErrorList: bankIdErrors,
      },
    });

    return usernameErrors.length + passwordErrors.length + bankIdErrors.length;
  };

  handleCentralSignIn = () => {
    window.location.href = process.env.REACT_APP_CENTRAL;
  };

  handleEmail = () => {
    const { username, errors, password, isChecked, bankId } = this.state;
    const {
      doCheckExistingUser,
      doAddUserName,
      doForgotPassword,
      bankingType,
      handleSignin,
      finInfo,
    } = this.props;

    const usernameErrors = checkErrors(username, VALIDATIONS.SIGNIN.username);
    console.log(usernameErrors);

    if (usernameErrors?.length === 0) {
      const existingUser = {
        credential: username,
        bankingType,
      };

      doCheckExistingUser(existingUser, (res) => {
        if (
          res.data.autoCreated &&
          res.data.status === "P" &&
          res.data.size === 1
        ) {
          console.log(res);
          this.processForgotPasswordFLow();
        } else if (res.data.size > 1) {
          // one of the duplicates is a pending central one user
          const userDetails = {
            credential: usernameCredentials(username),
            isChecked,
            bankingType,
            bankId,
            emailCheckOnly: true,
          };
          handleSignin(userDetails, res.data);
        } else {
          this.setState({
            errors: {
              ...errors,
              usernameErrorList: usernameErrors,
            },
            showPassword: usernameErrors?.length === 0,
          });

          this.togglePasswordFocus();
        }
      });
    } else {
      this.setState({
        errors: {
          ...errors,
          usernameErrorList: usernameErrors,
        },
        showPassword: usernameErrors?.length === 0,
      });

      this.togglePasswordFocus();
    }
  };

  processForgotPasswordFLow = () => {
    const { username } = this.state;
    const { doAddUserName, doForgotPassword, bankingType } = this.props;
    doAddUserName(username);
    const jointApplicationId = sessionStorage.getItem(
      STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
    );
    const jointApplicantSlot = sessionStorage.getItem(
      STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
    );
    const userDetails = {
      credential: username,
      otpTo: STRINGS.FORGOTPASSWORD.OTP_TO.EMAIL,
      bankingType,
      jointApplicationId,
      jointApplicantSlot,
    };
    console.log("userDetails", userDetails);
    doForgotPassword(userDetails, (forgotResponse) => {
      console.log(forgotResponse);
      if (forgotResponse.status === HTTP_STATUS.OK) {
        this.showResetMessage();
      }
    });
  };

  showResetMessage = () => {
    this.setState({
      showReset: true,
      modalType: AppConstants.MODALTYPE.INFORMATION,
      modal: {
        title: MODALMESSAGES.FORGOT_PASSWORD.SUCCESS_MAIL,
        description: STRINGS.FORGOTPASSWORD.MODAL.SSO,
      },
    });
  };

  togglePasswordFocus = () => {
    const p = document.getElementById("password");
    if (p) {
      p.focus();
    }
  };

  handleEmailKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleEmail();
    }
  };

  handlePasswordKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleSubmit(event);
    }
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleCloseBankIdError = () => {
    this.setState({
      showInvalidBankId: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      helpText: text,
    });
  };

  render() {
    const {
      username,
      password,
      bankId,
      isChecked,
      showModal,
      modalType,
      modal,
      errors,
      showPassword,
      showReset,
      showHelp,
      helpText,
      showInvalidBankId,
    } = this.state;
    const { showVaultClicked, finInfo, bankingType, showBankId } = this.props;
    const signInComponent = (
      <form className="[ signin-container ]">
        <div className="[ form-title-signin ]">
          {STRINGS.SIGNIN.VAULTSIGNINTITLE.split("_BANKING_TYPE_").join(
            bankingType ? bankingType.toUpperCase() : ""
          )}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.SIGNIN.FORM.USERNAMELABEL}
            </div>

            <input
              type="email"
              name="username"
              className={
                errors?.usernameErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              placeholder={STRINGS.SIGNIN.FORM.EMAILPLACEHOLDER}
              value={username}
              onChange={this.changeHandler}
              onKeyDown={this.handleEmailKeyDown}
              ref={this.userNameInput}
            />

            <div className="[ error-msg ]">{errors?.usernameErrorList[0]}</div>
          </div>
          {!showPassword && finInfo?.sso && (
            <>
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                    <button
                      type="button"
                      className="[ submit-btn btn ] [ w-100 ]"
                      onClick={() => this.handleEmail()}
                    >
                      {STRINGS.SIGNIN.FORM.CONTINUEBTN}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {showPassword && (
            <>
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.SIGNIN.FORM.PASSWORDLABEL}
                </div>
                <PasswordInput
                  changeHandler={this.changeHandler}
                  password={password}
                  passwordError={errors?.passwordErrorList}
                  placeholder={STRINGS.SIGNIN.FORM.PASSWORDPLACEHOLDER}
                  onKeyDownHandler={this.handlePasswordKeyDown}
                />
                <div className="[ error-msg ]">
                  {errors?.passwordErrorList[0]}
                </div>
              </div>

              {showBankId && (
                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                      ? STRINGS.SIGNIN.FORM.BANK_ID_LABEL_PAN
                      : STRINGS.SIGNIN.FORM.BANK_ID_LABEL_MEMBER_NO}
                  </div>
                  <input
                    type="text"
                    name="bankId"
                    className={
                      errors?.bankIdErrorList.length > 0
                        ? "[ form-control ] [ invalid-input ]"
                        : "[ form-control ]"
                    }
                    placeholder={
                      finInfo.onlineBankingUserName ===
                      DEBIT_CARD_ONLINE_BANKING
                        ? STRINGS.SIGNIN.FORM.BANK_ID_LABEL_PAN
                        : STRINGS.SIGNIN.FORM.BANK_ID_LABEL_MEMBER_NO
                    }
                    value={bankId}
                    onChange={this.changeHandler}
                    onKeyDown={this.handlePasswordKeyDown}
                    maxLength={
                      finInfo.onlineBankingUserName ===
                      DEBIT_CARD_ONLINE_BANKING
                        ? 4
                        : 10
                    }
                  />

                  <div className="[ error-msg ]">
                    {errors?.bankIdErrorList[0]}
                  </div>
                </div>
              )}

              <div className="[ mb-3 ]">
                <div className="[ row ] [ align-items-center ]">
                  <div className="[ col-6 ] [ d-flex align-items-center ] [ checkbox ]">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={this.handleChecked}
                      id="rememberMeCheckbox"
                    />
                    <label
                      htmlFor="rememberMeCheckbox"
                      className="[ checkbox-text ]"
                    >
                      {STRINGS.SIGNIN.FORM.REMEBERUSERCHECK}
                    </label>
                  </div>
                  <div className="[ col-6 ] [ forgot-link ]">
                    <span
                      className="[ link ]"
                      onClick={this.forgotPasswordHandler}
                      role="presentation"
                    >
                      {STRINGS.SIGNIN.FORM.FORGOTPASSWORDLINK}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {showPassword && (
          <>
            <div className="[ mb-3 ]">
              <div className="[ row ]">
                <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                  <button
                    type="button"
                    className="[ submit-btn btn ] [ w-100 ]"
                    onClick={this.handleSubmit}
                    disabled={!showPassword}
                  >
                    {STRINGS.SIGNIN.FORM.SUBMITBUTTON}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {finInfo.enableCentralSignIn &&
          finInfo?.sso &&
          (!bankingType ||
            bankingType !== AppConstants.BANKING_TYPE.BUSINESS) && (
            <>
              <div className="[ form-container sso-signin-container ]">
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <div className="[ divider d-flex align-items-center ]">
                        <p className="[ text-center mb-0 mx-3 ]">
                          {STRINGS.SIGNIN.FORM.OR}
                        </p>
                      </div>
                      <div className="[ alert alert-info ]" role="alert">
                        <h4 className="[ alert-heading ]">
                          {STRINGS.SIGNIN.FORM.CENTRAL_SIGNIN_HEADER}
                          <QuestionCircle
                            className="[ question-icon ]"
                            onClick={() =>
                              this.handleOpenHelp(
                                STRINGS.SIGNIN.FORM.SSO_MORE_INFO_DESCRIPTION
                              )
                            }
                          />
                        </h4>
                        <a
                          href="#"
                          className="[ link ]"
                          onClick={() => this.handleCentralSignIn()}
                          role="presentation"
                        >
                          <span>{STRINGS.SIGNIN.FORM.CENTRAL_SIGNIN}</span>
                          <span className="[ icon external-link-icon ]"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </form>
    );
    const infoContainer = (
      <div className="[ signin-secondary-container ]">
        <div className="[ info-title ]">
          {STRINGS.SIGNIN.INFOCONTAINER.REGISTERTITLE}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ] [ col-xl-12 ] [ offset-xl-0 ]">
              <button
                type="button"
                className="[ btn info-btn ] [ w-100 ]"
                onClick={this.handleRegistration}
              >
                {STRINGS.SIGNIN.INFOCONTAINER.REGISTERNAVIGATEBTN}
              </button>
            </div>
          </div>
        </div>

        <div className="[ row ][ info-message ] [ p-3 ]">
          {STRINGS.SIGNIN.INFOCONTAINER.REGISTERNOWINFO1}
        </div>
        <div className="[ version-num ]">{STRINGS.VERSION}</div>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showReset && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleReset}
            showModal={showReset}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showHelp && (
          <PopupModal
            modalType={AppConstants.MODALTYPE.CUSTOM}
            title={STRINGS.SIGNIN.FORM.SSO_MORE_INFO_TITLE}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showInvalidBankId && (
          <PopupModal
            type={"Hmmm..."}
            description={modal.description}
            toggleModal={this.handleCloseBankIdError}
            showModal={showInvalidBankId}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={signInComponent}
          infoComponent={infoContainer}
          securityText={STRINGS.INFOBANNER.SECURESESSIONTEXT}
          showVaultClicked={() => showVaultClicked}
          handleBack={this.handleBack}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  signinState: state.SigninReducer,
  forgotPasswordState: state.ForgotPasswordReducer,
  bankingType: state.MarketplaceReducer.bankingType,
});
SignIn.propTypes = {
  forgotPasswordState: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  handleSignin: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  showVaultClicked: PropTypes.func,
  doCheckExistingUser: PropTypes.func.isRequired,
  doForgotPassword: PropTypes.func.isRequired,
  doAddUserName: PropTypes.func.isRequired,
};
SignIn.defaultProps = {
  forgotPasswordState: {},
  handleSignin: () => {},
  history: {},
  showVaultClicked: () => {},
  finInfo: {},
};
export default connect(mapStateToProps)(SignIn);
