const FIInformation = {
  FI_NAME: "Mainstreet Credit Union",
  FI_APPLICATION_NAME: "Open Anytime",
  FI_EMAIL: "https://www.mainstreetcu.ca/contact",
  FI_TEL: "1-866-380-8008",
  FI_CONDITIONS: "https://banking.mainstreetcu.ca/OnlinePolicies/Legal/",
  FI_PRIVACY: "https://banking.mainstreetcu.ca/OnlinePolicies/Privacy/",
  FI_SITE: "https://www.mainstreetcu.ca/",
  FI_OVERDRAFT_URL: "https://www.mainstreetcu.ca/rates",
  FI_BENEFICIARY_URL: "https://www.mainstreetcu.ca/",
  FI_SHARE_AMOUNT: "$50",
  FI_BUSINESS_SHARE_AMOUNT: "$50",
  FI_OVERDRAFT_DEFAULT_AMOUNT: "$100.00",
  FI_BOOK_APPOINTMENT:
    "https://mainstreetcu.coconutcalendar.com/service?utm_source=AOS_LOS&utm_medium=self_serve&utm_campaign=open_anytime",
  FI_FINANCIAL_PLANNING_APPOINTMENT:
    "https://mainstreetcu.coconutcalendar.com/s/OA-Financial-Planning",
};

export default FIInformation;
