import React, { Component } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import PropTypes from "prop-types";
import { PasswordInput } from "Components/Common/PasswordInput/PasswordInput";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import PopupModal from "../Common/PopupModal/PopupModal";
import { DEBIT_CARD_ONLINE_BANKING } from "../../Communication/Constants";
import { Clipboard, Eye, EyeSlashFill } from "react-bootstrap-icons";
import { copyTextToClipboard } from "../../Utils/CommonUtilities";
import { generate } from "generate-password";
// import PropTypes from "prop-types";

class OnlineBanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noPasswordSpecified: false,
      password: "", // first user entered password
      confirmPasswordValue: "", // confirm password user entered value
      passwordChecksResult: {}, // object for storing run-time validations checks
      confirmPasswordError: false, // error message if passwords does not match
      isFormValid: false,
      passwordCriteriaMatch: true,
      show: false,
      isCopiedSuccessVisible: false,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.ONLINE_BANKING,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  setFormData = (data) => {
    console.log(data);
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
  };

  validateInput = (event) => {
    const password = event.target.value;
    const { confirmPasswordValue } = this.state;
    let isPasswordSpecified = false;
    let isPasswordMatch = false;
    if (password === "" && confirmPasswordValue === "") {
      this.setState({
        noPasswordSpecified: true,
      });
    } else {
      this.setState({
        noPasswordSpecified: false,
      });
      isPasswordSpecified = true;
    }
    if (password !== confirmPasswordValue) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordValue,
      });
    } else {
      this.setState({
        confirmPasswordError: false,
        confirmPasswordValue,
      });
      isPasswordMatch = true;
    }
    const { value } = event.target;
    const { passwdCriteria } = this.props;
    const data = value;
    this.setState({
      password: data,
      isFormValid: isPasswordSpecified && isPasswordMatch,
    });

    /**
     * passwordValidationRegex represents
     * object with regex and code values
     */

    const passwordValidationRegex = passwdCriteria.map(({ regex, code }) => ({
      regex,
      code,
    }));
    passwordValidationRegex.map((rx) => {
      const isValid = new RegExp(rx.regex).test(data);
      const { passwordChecksResult } = this.state;
      passwordChecksResult[rx.code] = isValid;
      return this.setState(
        {
          passwordChecksResult,
        },
        () => {
          const allTrue = Object.keys(passwordChecksResult).every((k) => {
            return passwordChecksResult[k];
          });
          if (allTrue) {
            this.setState({ passwordCriteriaMatch: true });
          } else {
            this.setState({ passwordCriteriaMatch: false });
          }
        }
      );
    });
  };
  /**
   *
   * @param {object} event represents
   * browser event on confirm password
   * text input
   */

  handleConfirmPassword = (event) => {
    const confirmPasswordValue = event.target.value;
    const { password } = this.state;
    if (confirmPasswordValue === "" && password === "") {
      this.setState({
        noPasswordSpecified: true,
      });
    } else {
      this.setState({
        noPasswordSpecified: false,
      });
    }
    if (password !== confirmPasswordValue) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordValue,
        isFormValid: false,
      });
    } else {
      this.setState({
        confirmPasswordError: false,
        confirmPasswordValue,
        isFormValid: true,
      });
    }
  };

  handleContinue = () => {
    const { handleSetPasswordRequest, finInfo, autoGenPassword } = this.props;
    const { password, isFormValid, passwordCriteriaMatch } = this.state;
    if (finInfo.onlineBankingPasswordAutoGen) {
      handleSetPasswordRequest(autoGenPassword);
    } else {
      if (password === "") {
        this.setState({
          noPasswordSpecified: true,
        });
      } else if (isFormValid && passwordCriteriaMatch) {
        handleSetPasswordRequest(password);
      }
    }
  };

  toggleShow = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  handleCopyToClipboardClick = () => {
    // Copy to clipboard logic here...

    // Update state to show the 'copied-success' div
    this.setState({ isCopiedSuccessVisible: true });

    // After a certain duration, hide it again
    setTimeout(() => {
      this.setState({ isCopiedSuccessVisible: false });
    }, 3000); // Adjust the duration as needed
  };
  handleMemberCopyToClipboardClick = () => {
    // Copy to clipboard logic here...

    // Update state to show the 'copied-success' div
    this.setState({ isMemberCopiedSuccessVisible: true });

    // After a certain duration, hide it again
    setTimeout(() => {
      this.setState({ isMemberCopiedSuccessVisible: false });
    }, 3000); // Adjust the duration as needed
  };

  render() {
    const {
      passwdCriteria,
      steps,
      activeStepID,
      handleSkip,
      type,
      modal,
      showModal,
      toggleModal,
      handlePopupModalBtnClick,
      finInfo,
      applicantData,
      debitCardNumber,
      autoGenPassword,
    } = this.props;
    const {
      password,
      confirmPasswordError,
      passwordChecksResult,
      confirmPasswordValue,
      noPasswordSpecified,
      show,
    } = this.state;

    const onlineBankingContainer = (
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          this.handleContinue();
        }}
        className="[ set-online-banking-container ]"
      >
        {steps.length > 0 && <Stepper steps={steps} active={activeStepID} />}

        <div className="[ form-title ]">{STRINGS.ONLINE_BANKING.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ card-title ] ">
              {STRINGS.ONLINE_BANKING.REGISTER_FOR_ONLINE_BANKING_LABEL}
            </div>
            <div className="[ card-text ] [ additional-text-container-1 ]">
              <span
                dangerouslySetInnerHTML={{
                  __html: STRINGS.ONLINE_BANKING.ONLINE_BANKING_TXT,
                }}
              />
            </div>
            <div className="[ card-text ] [ additional-text-container-2 ]">
              <span
                dangerouslySetInnerHTML={{
                  __html: STRINGS.ONLINE_BANKING.ONLINE_BANKING_TXT1,
                }}
              />
            </div>
            {((finInfo.showOnlineBankingMemberNumber &&
              finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING) ||
              finInfo.showDebitCardNumber) && (
              <div className="[ card-text ] [ additional-text-container-3 ]">
                <span
                  dangerouslySetInnerHTML={{
                    __html: STRINGS.ONLINE_BANKING.COPY_DEBIT_CARD_INSTRUCTIONS,
                  }}
                />
                {STRINGS.ONLINE_BANKING.DEBIT_CARD_NUMBER_LABEL}{" "}
                {show
                  ? debitCardNumber
                  : Array(debitCardNumber.length + 1).join("*")}{" "}
                {show ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="hideTooltip">Hide</Tooltip>}
                  >
                    <EyeSlashFill
                      style={{ cursor: "pointer" }}
                      onClick={this.toggleShow}
                    />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="showTooltip">Show</Tooltip>}
                  >
                    <Eye
                      style={{ cursor: "pointer" }}
                      onClick={this.toggleShow}
                    />
                  </OverlayTrigger>
                )}{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="copyTooltip">
                      {STRINGS.ONLINE_BANKING.COPY_TO_CLIPBOARD}
                    </Tooltip>
                  }
                >
                  <Clipboard
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      copyTextToClipboard(debitCardNumber);
                      this.handleCopyToClipboardClick();
                    }}
                  />
                </OverlayTrigger>
                <div
                  className={`[ copy-to-clipboard-success ] ${
                    this.state.isCopiedSuccessVisible ? "show" : ""
                  }`}
                  id="copied-success"
                >
                  {STRINGS.ONLINE_BANKING.COPIED_TO_CLIPBOARD}
                </div>
              </div>
            )}
            {finInfo.showOnlineBankingMemberNumber &&
              finInfo.onlineBankingUserName !== DEBIT_CARD_ONLINE_BANKING && (
                <div className="[ card-text ]">
                  <strong>{STRINGS.ONLINE_BANKING.MEMBER_NUMBER_LABEL}</strong>{" "}
                  {applicantData?.member.memberNumber}{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="copyTooltip">
                        {STRINGS.ONLINE_BANKING.COPY_TO_CLIPBOARD}
                      </Tooltip>
                    }
                  >
                    <Clipboard
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        copyTextToClipboard(applicantData?.member.memberNumber);
                        this.handleMemberCopyToClipboardClick();
                      }}
                    />
                  </OverlayTrigger>
                  <div
                    className={`[ copy-to-clipboard-success ] ${
                      this.state.isMemberCopiedSuccessVisible ? "show" : ""
                    }`}
                    id="copied-success"
                  >
                    {STRINGS.ONLINE_BANKING.COPIED_TO_CLIPBOARD}
                  </div>
                </div>
              )}

            {finInfo.onlineBankingPasswordAutoGen && (
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.ONLINE_BANKING.AUTO_GEN_TEMPORARY_PASSWORD_LABEL}
                </div>

                <div
                  className="[ alert alert-info ] [ password-box ]"
                  role="alert"
                >
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      {autoGenPassword}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="copyTooltip">
                            {STRINGS.ONLINE_BANKING.COPY_TO_CLIPBOARD}
                          </Tooltip>
                        }
                      >
                        <Clipboard
                          id="copy-password"
                          style={{ cursor: "pointer" }}
                          className="copy-to-clipboard"
                          onClick={() => {
                            copyTextToClipboard(autoGenPassword);
                            this.handleCopyToClipboardClick();
                          }}
                        ></Clipboard>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div
                  className={`[ copy-to-clipboard-success ] ${
                    this.state.isCopiedSuccessVisible ? "show" : ""
                  }`}
                  id="copied-success"
                >
                  {STRINGS.ONLINE_BANKING.COPIED_TO_CLIPBOARD}
                </div>

                <div
                  className="[ mb-3 mt-3 ] [ alert alert-warning ] [ password-msg ]"
                  role="alert"
                >
                  <div className="[ row ] [ mb-3 ]">
                    <div className="[ col-12 ]">
                      <p>
                        <i className="[ fas fa-exclamation-circle ]"></i>
                        {
                          STRINGS.ONLINE_BANKING
                            .AUTO_GEN_TEMPORARY_PASSWORD_MSG1
                        }
                      </p>
                      <p>
                        <i className="[ fas fa-exclamation-circle ]"></i>
                        {
                          STRINGS.ONLINE_BANKING
                            .AUTO_GEN_TEMPORARY_PASSWORD_MSG2
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!finInfo.onlineBankingPasswordAutoGen && (
              <>
                <div className="[ form-label ] ">
                  {STRINGS.ONLINE_BANKING.PROVIDE_TEMPORARY_PASSWORD_LABEL}
                </div>

                <PasswordInput
                  id="Password-1"
                  changeHandler={this.validateInput}
                  password={password}
                />
                <div className="[ error-msg ]">
                  {noPasswordSpecified
                    ? STRINGS.ONLINE_BANKING.ERROR.PASSWORD_REQUIRED
                    : null}
                </div>

                <div className="[ drawer-content-container ] [ pasword-rules ]">
                  <div className="[ drawer-content-container__title ]">
                    {STRINGS.SETPASSWORD.FORM.PASSWORDCRITERIA}
                  </div>

                  <div className="[ validations-rules-wrapper ]">
                    {passwdCriteria &&
                      typeof passwdCriteria !== "undefined" &&
                      passwdCriteria.map((record) => {
                        return (
                          <div
                            key={record.code}
                            className={
                              record.code &&
                              passwordChecksResult[record.code] &&
                              passwordChecksResult[record.code] === true
                                ? "[ icon check-icon ] [ set-password-validations-item ]"
                                : "[ icon invalid-icon ] [ set-password-validations-item ]"
                            }
                          >
                            {record.message}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
          </div>

          {!finInfo.onlineBankingPasswordAutoGen && (
            <>
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.ONLINE_BANKING.RETYPEPASSWORDLABEL}
                </div>

                <PasswordInput
                  id="Password-2"
                  changeHandler={this.handleConfirmPassword}
                  password={confirmPasswordValue}
                />
                <div className="[ error-msg ]">
                  {confirmPasswordError
                    ? STRINGS.SETPASSWORD.ERROR.CONFIRM_PASSWORD_ERROR
                    : null}
                </div>
              </div>
              <div
                className="[ mb-3 ] [ alert alert-warning ] [ legalese-container ]"
                role="alert"
              >
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <span>
                      <i className="[ fas fa-exclamation-circle ]"></i>{" "}
                      {
                        STRINGS.ONLINE_BANKING
                          .REGISTER_FOR_ONLINE_BANKING_LEGALESE
                      }
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button type="submit" className="[ submit-btn btn ] [ w-100 ]">
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
          <div className="[ row ] [ custom-radios ] [ pt-3 ]">
            <div className="[ col-lg-4 ] [ offset-lg-4 ]">
              <button
                type="button"
                className="[ btn ] [ w-100 ] [ skip-funding ]"
                onClick={handleSkip}
              >
                {STRINGS.ONLINE_BANKING.SKIP}
              </button>
            </div>
            <div className="[ col-lg-10 ] [ offset-lg-1 ] [ skip-funding-disclaimer ]">
              <small>{STRINGS.ONLINE_BANKING.SKIP_DISCLAIMER}</small>
            </div>
          </div>
        </div>
      </Form>
    );
    return (
      // onlineBankingContainer
      <>
        <AuthBody
          actionComponent={onlineBankingContainer}
          memberCreation
          onlineBanking
          handleBack={this.handleBack}
        />
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={() => handlePopupModalBtnClick()}
            closeBtnClick={() => handlePopupModalBtnClick()}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}

OnlineBanking.propTypes = {
  getDataFromServer: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  passwdCriteria: PropTypes.arrayOf(PropTypes.any),
  handleSetPasswordRequest: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  handleSkip: PropTypes.func,
  showModal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  handlePopupModalBtnClick: PropTypes.func.isRequired,
  finInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  applicantData: PropTypes.objectOf(PropTypes.any).isRequired,
  debitCardNumber: PropTypes.objectOf(PropTypes.any),
};
OnlineBanking.defaultProps = {
  getDataFromServer: () => {},
  passwdCriteria: [],
  steps: [],
  activeStepID: -1,
  handleSkip: () => {},
  debitCardNumber: null,
};

export default OnlineBanking;
