import AppConstants from "Constants/AppConstants";
import { differenceInCalendarYears, parse } from "date-fns";
import {
  DEBIT_CARD_ONLINE_BANKING,
  HTTP_STATUS,
  S_APPLICANT_ID,
  S_APPLICANT_MEMBER_NO,
  S_EMAIL,
  S_INSTNT_FINGER_PRINT,
  S_INSTNT_TXN_ID,
  S_MEMBER_VAULT_UPDATE,
  S_MSISDN,
  TOKEN_KEY,
} from "../Communication/Constants";
import * as ROUTES from "../Constants/Routes";

export const isNewMember = (applicantData) => {
  console.log("isNewMember", applicantData);
  let result = false; // default to existing member already
  const mainApplicantId = sessionStorage.getItem(S_APPLICANT_ID);
  if (mainApplicantId & applicantData && applicantData.id !== mainApplicantId) {
    // a new membership
  } else if (
    !applicantData ||
    (applicantData.member?.memberNumber &&
      applicantData.member?.memberNumber.startsWith("P"))
  ) {
    result = true;
  }
  return result;
};

export const isExistingMember = (applicantData) => {
  console.log("isExistingMember", applicantData);
  let result = false; // default to existing member already
  if (
    applicantData?.member?.memberNumber &&
    !applicantData?.member?.memberNumber.startsWith("P")
  ) {
    result = true;
  }
  return result;
};

export const showGettingStarted = (activeProduct, finInfo) => {
  let result = true;
  const mainApplicantId = sessionStorage.getItem(S_APPLICANT_ID);
  const mainMemberNo = sessionStorage.getItem(S_APPLICANT_MEMBER_NO);
  console.log("showGettingStarted", activeProduct, finInfo);
  if (
    finInfo.jointMembership &&
    mainApplicantId &&
    mainMemberNo &&
    !mainMemberNo.startsWith("P")
  ) {
    result = false;
  } else if (activeProduct.isJointProduct) {
    result = false;
  }
  console.log("showGettingStarted", result);
  return result;
};

export const showCompliance = (
  applicantData,
  applicationCompleteList,
  inSessionJointApplicant,
  activeProduct,
  finInfo,
  sessionCompletedProducts
) => {
  let result = true;
  console.log(
    "showCompliance",
    applicantData?.acquireType,
    applicationCompleteList?.length,
    inSessionJointApplicant,
    activeProduct,
    finInfo,
    sessionCompletedProducts
  );
  const mainApplicantId = sessionStorage.getItem(S_APPLICANT_ID);
  const mainMemberNo = sessionStorage.getItem(S_APPLICANT_MEMBER_NO);
  if (
    finInfo.jointMembership &&
    mainApplicantId &&
    mainMemberNo &&
    !mainMemberNo.startsWith("P") &&
    !inSessionJointApplicant
  ) {
    result = false;
  } else if (
    (applicantData?.acquireType === "search" ||
      (applicationCompleteList &&
        applicationCompleteList.length > 0 &&
        applicationCompleteList[0]?.id !== activeProduct.applicationId) ||
      sessionStorage.getItem("TEMP_HIDE") === "true") &&
    !inSessionJointApplicant &&
    !activeProduct.prospect
  ) {
    result = false;
    if (
      allowKycEdit(
        applicantData,
        sessionCompletedProducts,
        finInfo,
        inSessionJointApplicant
      )
    ) {
      result = true;
    }
  }
  console.log("result", result);
  return result;
};

export const showIncomePage = (activeProduct, config) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    if (activeProduct.loanPurposeCode === config?.consolidateDebtCode) {
      return true;
    }
    return false;
  }
  return true;
};

export const showAssetPage = (activeProduct, config) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    if (activeProduct.loanPurposeCode === config?.consolidateDebtCode) {
      return true;
    }
    return false;
  }
  return true;
};

export const showExpensePage = (activeProduct, config) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    if (activeProduct.loanPurposeCode === config?.consolidateDebtCode) {
      return true;
    }
    return false;
  }
  return true;
};

export const showLiabilityPage = (activeProduct, config) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    if (activeProduct.loanPurposeCode === config?.consolidateDebtCode) {
      return true;
    }
    return false;
  }
  return true;
};

export const showDocumentUpload = (activeProduct) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    return false;
  }
  return true;
};

export const showDebitCard = (
  activeProduct,
  applicationCompleteList,
  applicantData,
  inSessionJointApplicant,
  finInfo
) => {
  console.log(
    "showDebitCard",
    activeProduct,
    applicationCompleteList,
    applicantData,
    finInfo
  );
  let result = false;
  const mainApplicantId = sessionStorage.getItem(S_APPLICANT_ID);
  const mainMemberNo = sessionStorage.getItem(S_APPLICANT_MEMBER_NO);
  if (
    finInfo?.jointMembership &&
    mainApplicantId &&
    mainMemberNo &&
    !mainMemberNo?.startsWith("P") &&
    !inSessionJointApplicant
  ) {
    result = false;
  } else if (
    (applicantData?.acquireType === "create" &&
      (applicationCompleteList?.length === 0 ||
        (applicationCompleteList?.length === 1 &&
          applicationCompleteList[0]?.id === activeProduct.applicationId)) &&
      activeProduct.bundleProducts?.length > 0) ||
    (inSessionJointApplicant &&
      activeProduct.bundleProducts?.length > 0 &&
      isNewMember(applicantData))
  ) {
    result = true;
  }
  console.log("showDebitCard", result);
  return result;
};

export const showTaxInfo = (
  applicationCompleteList,
  applicantData,
  inSessionJointApplicant,
  activeProduct,
  finInfo,
  sessionCompletedProducts
) => {
  console.log(
    "showTaxInfo",
    applicationCompleteList,
    applicantData,
    inSessionJointApplicant,
    activeProduct,
    finInfo,
    sessionCompletedProducts
  );
  let result = false;
  const mainApplicantId = sessionStorage.getItem(S_APPLICANT_ID);
  const mainMemberNo = sessionStorage.getItem(S_APPLICANT_MEMBER_NO);
  if (
    finInfo.jointMembership &&
    mainApplicantId &&
    mainMemberNo &&
    !mainMemberNo?.startsWith("P") &&
    !inSessionJointApplicant
  ) {
    result = false;
  } else if (
    ((applicationCompleteList?.length === 0 ||
      (applicationCompleteList?.length === 1 &&
        applicationCompleteList[0]?.id === activeProduct.applicationId)) &&
      isNewMember(applicantData)) ||
    inSessionJointApplicant ||
    activeProduct.prospect
  ) {
    result = true;
  } else if (
    allowKycEdit(
      applicantData,
      sessionCompletedProducts,
      finInfo,
      inSessionJointApplicant
    ) &&
    finInfo.kycAllowedEdit.includes(AppConstants.KYC.SIN)
  ) {
    result = true;
  }
  return result;
};

export const showOnlineBanking = (
  activeProduct,
  applicationCompleteList,
  applicantData,
  finInfo,
  onlineBankingInfo,
  fundProduct
) => {
  console.log(
    "showOnlineBanking",
    activeProduct,
    applicationCompleteList,
    applicantData,
    finInfo?.onlineBankingUserName,
    onlineBankingInfo,
    fundProduct
  );
  let showOLB = false;
  if (fundProduct?.uploadedAt) {
    if (finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING) {
      if (
        fundProduct?.debitProductId &&
        (applicantData.acquireType === "create" ||
          applicantData.addedToBankingAt ||
          // applicantData.submittedToDecisionAt ||
          applicantData.submittedOnlineUserAt)
      ) {
        if (activeProduct?.applicationId) {
          // there is an application in process
          if (applicationCompleteList?.length > 0) {
            if (applicationCompleteList.length === 1) {
              if (
                applicationCompleteList[0].id === activeProduct.applicationId
              ) {
                showOLB = true;
              }
            }
          } else {
            showOLB = true;
          }
        } else if (applicationCompleteList?.length === 1) {
          showOLB = true;
        }
      }
    } else {
      const uploadedApplications = applicationCompleteList.filter(
        (item) => item.uploadedAt
      );
      if (
        uploadedApplications.length <= 1 &&
        applicantData.acquireType === "create"
      ) {
        if (uploadedApplications.length === 1) {
          if (uploadedApplications[0].id === fundProduct.applicationId) {
            showOLB = true;
          }
        } else {
          showOLB = true;
        }
        if (showOLB && finInfo.onlineBankingOnlyWithDebitCard) {
          if (!fundProduct.enableDebitCard) {
            showOLB = false;
          }
        }
      }
    }
  }

  return showOLB;
};

export const showOnlineBankingForInSession = (
  activeProduct,
  applicationCompleteList,
  applicantData,
  finInfo,
  onlineBankingInfo,
  inSessionJointApplicant
) => {
  console.log(
    "showOnlineBankingForInSession",
    activeProduct,
    applicationCompleteList,
    applicantData,
    finInfo?.onlineBankingUserName,
    onlineBankingInfo,
    inSessionJointApplicant
  );
  let result = false;
  if (finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING) {
    if (
      inSessionJointApplicant?.debitCardProductId &&
      (applicantData.acquireType === "create" ||
        applicantData.addedToBankingAt ||
        // applicantData.submittedToDecisionAt ||
        applicantData.submittedOnlineUserAt)
    ) {
      result = true;
    }
  }
  return result;
};

export const showError = (error) => {
  const result = true;

  const accessToken = sessionStorage.getItem("token");

  if (accessToken) {
  } else {
  }

  return result;
};

export const getProductIdByAge = (dateOfBirth, productList) => {
  console.log("getProductIdByAge", dateOfBirth, productList);
  const dob = dateOfBirth ? parse(dateOfBirth, "yyyy-MM-dd", new Date()) : null;
  const memberAge = differenceInCalendarYears(new Date(), dob);
  console.log("memberAge", memberAge);
  const product = productList.find(
    (p) => memberAge >= p.bracket.min && memberAge <= p.bracket.max
  );
  console.log("product", product);
  return product.productId;
};

export const getProductIdInActiveProduct = (activeProduct, applicantData) => {
  console.log("getProductIdInActiveProduct", activeProduct);
  let bundleProductId = activeProduct.productId;
  if (activeProduct.productList && activeProduct.productList.length > 0) {
    if (applicantData?.dob) {
      const dob = parse(applicantData.dob, "yyyy-MM-dd", new Date());
      const memberAge = differenceInCalendarYears(new Date(), dob);
      console.log("memberAge", memberAge);
      const bundleProduct = activeProduct.productList.find(
        (p) => memberAge >= p.bracket.min && memberAge <= p.bracket.max
      );
      bundleProductId = bundleProduct.productId;
    } else {
      bundleProductId = activeProduct.productList[0].productId;
    }
  }
  console.log("bundleProductId", bundleProductId);
  return bundleProductId;
};

export const calculateAge = (birthDate) => {
  const today = new Date();
  let ageNow = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    ageNow -= 1;
  }
  console.log(ageNow);
  return ageNow;
};

export const verifyAge = (dateOfBirth, activeProduct) => {
  console.log("verifyAge", dateOfBirth, activeProduct);
  let valid = true;
  const dob = dateOfBirth ? parse(dateOfBirth, "yyyy-MM-dd", new Date()) : null;
  const memberAge = calculateAge(dob);
  console.log("memberAge", memberAge);
  if (activeProduct.age) {
    if (
      memberAge < activeProduct.age.min ||
      memberAge > activeProduct.age.max
    ) {
      valid = false;
    }
  }
  return valid;
};

export const allowedProvince = (province, finInfo) => {
  let allowed = true;

  if (
    finInfo?.allowedProvinceForNewMember &&
    province !== finInfo.allowedProvinceForNewMember
  ) {
    allowed = false;
  }

  return allowed;
};

export const getMemberBenefit = (dateOfBirth, memberBenefit, defaultValue) => {
  console.log("getMemberBenefit", dateOfBirth, memberBenefit, defaultValue);
  let benefit = defaultValue;
  const dob = dateOfBirth ? parse(dateOfBirth, "yyyy-MM-dd", new Date()) : null;
  const memberAge = differenceInCalendarYears(new Date(), dob);
  console.log("member age", memberAge);
  if (memberBenefit && memberBenefit.length > 0) {
    const data = memberBenefit.find(
      (item) => item.min <= memberAge && memberAge <= item.max
    );
    if (data) {
      benefit = data.code;
    }
  }
  return benefit;
};

export const getRFSAddress = (addressWithApartment) => {
  let result = addressWithApartment;
  const currentAddressArray = addressWithApartment.split("-");
  if (currentAddressArray.length > 1) {
    result = currentAddressArray[1].trim();
  }
  return result;
};

export const getDNAAddress = (addressWithApartment) => {
  let result = addressWithApartment;
  const currentAddressArray = addressWithApartment.split(" - ");
  if (currentAddressArray.length > 1) {
    result = currentAddressArray[1].trim();
  }
  return result;
};

export const getDNAApartment = (addressWithApartment) => {
  let result = "";
  const currentAddressArray = addressWithApartment.split(" - ");
  if (currentAddressArray.length > 1) {
    result = currentAddressArray[0].trim();
  }
  return result;
};

export const getNatureOfBusiness = (
  activeProduct,
  applicationCompleteList,
  applicantData,
  finInfo,
  productInformationData
) => {
  console.log("getNatureOfBusiness", productInformationData);
  let result = {};
  if (finInfo.natureOfBusiness) {
    if (
      applicantData?.acquireType === "create" &&
      (applicationCompleteList?.length === 0 ||
        (applicationCompleteList?.length === 1 &&
          applicationCompleteList[0]?.id === activeProduct.applicationId)) &&
      // activeProduct.bundleProducts?.length > 0 &&
      !activeProduct.jointExternalApplicationId
    ) {
      if (activeProduct.type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT) {
        if (activeProduct.hasOverdraftProtection) {
          result = {
            natureOfBusiness: finInfo.natureOfBusiness.demandProductOverdraft,
          };
        } else {
          result = {
            natureOfBusiness: finInfo.natureOfBusiness.demandProduct,
          };
        }
      } else {
        result = {
          natureOfBusiness: finInfo.natureOfBusiness.nonDemandProduct,
        };
      }
    }
  }
  return result;
};

export const getNatureOfBusinessForJoint = (activeProduct, finInfo) => {
  console.log("getNatureOfBusinessForJoint");
  let result = {};
  if (finInfo.natureOfBusiness) {
    if (activeProduct.type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT) {
      if (activeProduct.hasOverdraftProtection) {
        result = {
          natureOfBusiness: finInfo.natureOfBusiness.demandProductOverdraft,
        };
      } else {
        result = {
          natureOfBusiness: finInfo.natureOfBusiness.demandProduct,
        };
      }
    } else {
      result = {
        natureOfBusiness: finInfo.natureOfBusiness.nonDemandProduct,
      };
    }
  }
  return result;
};

export const getJointInSessionFlow = (
  currentProcessTemplateFlow,
  applicantData
) => {
  const flow = [];

  AppConstants.JOINT_APPLICANT.FLOW.forEach((item) => {
    if (item.componentName === "TERMS_AND_CONDITIONS") {
      flow.push(item);
    } else {
      const currentFLow = currentProcessTemplateFlow.find(
        (x) => x.componentName === item.componentName
      );
      if (currentFLow) {
        if (
          applicantData &&
          isExistingMember(applicantData) &&
          (item.componentName === "TAX_INFO" ||
            item.componentName === "COMPLIANCE")
        ) {
          // do not add steps for existing member
        } else {
          flow.push({
            ...item,
            index: flow.length - 1,
          });
        }
      }
    }
  });

  return flow;
};

export const showJoint = (
  finInfo,
  applicantData,
  applicationCompleteList,
  activeProduct
) => {
  console.log(
    "showJoint",
    finInfo,
    applicantData,
    applicationCompleteList,
    activeProduct
  );
  let showJointPage = true;

  if (activeProduct?.isJointProduct) {
    showJointPage = true;
  } else if (finInfo.disableJointForExistingMember) {
    if (isNewMember(applicantData)) {
      showJointPage = applicationCompleteList?.length <= 0;
    } else {
      showJointPage = false;
    }
  }
  console.log("showJointPage", showJointPage);
  return showJointPage;
};

export const stepAlreadyProcessed = (
  currentStep,
  lastFinishedStepIndex,
  processFlow
) => {
  console.log(
    "stepAlreadyProcessed",
    currentStep,
    lastFinishedStepIndex,
    processFlow
  );
  let result = false;

  if (processFlow) {
    const currentIndex = processFlow.find(
      (e) => e.componentName === currentStep
    )?.index;
    if (currentIndex && currentIndex <= lastFinishedStepIndex) {
      result = true;
    }
  }
  console.log(result);
  return result;
};

export const countryAndProvinceIsValid = (applicantData, finInfo) => {
  let result = true;
  console.log("countryAndProvinceIsValid", applicantData, finInfo);
  if (!isNewMember(applicantData)) {
    if (
      result &&
      applicantData?.id &&
      applicantData?.address?.currentAddressCountry &&
      finInfo.validCountryCodes &&
      Array.isArray(finInfo.validCountryCodes)
    ) {
      if (
        !finInfo.validCountryCodes.includes(
          applicantData?.address?.currentAddressCountry
        )
      ) {
        console.log("invalid currentAddressCountry");
        result = false;
      }
    }
    if (
      result &&
      applicantData?.id &&
      applicantData?.address?.mailingCountry &&
      !applicantData.address.mailingSameAsCivic &&
      finInfo.validCountryCodes &&
      Array.isArray(finInfo.validCountryCodes)
    ) {
      if (
        !finInfo.validCountryCodes.includes(
          applicantData?.address?.mailingCountry
        )
      ) {
        console.log("invalid mailingCountry");
        result = false;
      }
    }
    if (
      result &&
      applicantData.address &&
      !applicantData.address?.currentAddressCountry &&
      Array.isArray(finInfo.validProvinceCodes)
    ) {
      if (
        !finInfo.validProvinceCodes.includes(
          applicantData?.address?.currentAddressProvince
        )
      ) {
        console.log("invalid currentAddressProvince");
        result = false;
      }
    }
    if (
      result &&
      applicantData.address &&
      !applicantData.address.mailingSameAsCivic &&
      !applicantData.address?.mailingCountry &&
      applicantData.address?.mailingProvince &&
      Array.isArray(finInfo.validProvinceCodes)
    ) {
      if (
        !finInfo.validProvinceCodes.includes(
          applicantData?.address?.mailingProvince
        )
      ) {
        console.log("invalid mailingProvince");
        result = false;
      }
    }
  }

  return result;
};

export const memberHasExpiredId = (applicantData) => {
  let result = false;

  if (
    !isNewMember(applicantData) &&
    !applicantData.member.idDetail1 &&
    !applicantData.member.idType1 &&
    !applicantData.member.placeOfIssuance1 &&
    !applicantData.member.idCountryOfIssuance1 &&
    !applicantData.member.issueDate1 &&
    !applicantData.member.expiryDate1 &&
    !applicantData.member.verifyId1
  ) {
    result = true;
  }
  return result;
};

export const allowKycEdit = (
  applicantData,
  sessionCompletedProducts,
  finInfo,
  inSessionJointApplicant
) => {
  let result = false;

  if (
    !isNewMember(applicantData) &&
    Array.isArray(finInfo.kycAllowedEdit) &&
    finInfo.kycAllowedEdit.length > 0
  ) {
    if (sessionCompletedProducts.length > 0) {
      if (inSessionJointApplicant) {
        result = true;
      }
    } else {
      result = true;
    }
  }
  console.log("allowKycEdit", result);
  return result;
};

export const allowKycEditForJointInSession = (applicantData, finInfo) => {
  let result = false;
  console.log("allowKycEditForJointInSession", applicantData, finInfo);
  if (
    applicantData &&
    !isNewMember(applicantData) &&
    Array.isArray(finInfo.kycAllowedEdit) &&
    finInfo.kycAllowedEdit.length > 0
  ) {
    result = true;
  }
  return result;
};

export const getKycData = (applicantData, finInfo) => {
  const result = {
    instntTxnId: sessionStorage.getItem(S_INSTNT_TXN_ID),
    fingerprint: getFingerPrint(),
    instntToken: process.env.REACT_APP_INSTNT_KEY,
    email: sessionStorage.getItem(S_EMAIL),
    msisdn: sessionStorage.getItem(S_MSISDN),
    firstName: applicantData?.member?.name,
    middleName: applicantData?.member?.middleName,
    surName: applicantData?.member?.surname,
    dateOfBirth: applicantData?.member?.dob,
    addressLine1: applicantData?.address?.currentAddressLine1,
    addressLine2: applicantData?.address?.currentAddressLine2,
    city: applicantData?.address?.currentAddressCity,
    state: applicantData?.address?.currentAddressProvince,
    postalCode: applicantData?.address?.currentAddressPostalCode,
    country: finInfo?.verificationCountry,
  };
  return result;
};

export const isThirdPartyDataEqual = (applicantData, vaultMember) => {
  let result = true;

  if (applicantData.thirdParties && !vaultMember.thirdParties) {
    result = false;
  } else if (!applicantData.thirdParties && vaultMember.thirdParties) {
    result = false;
  } else if (
    applicantData.thirdParties.length !== vaultMember.thirdParties.length
  ) {
    result = false;
  } else if (
    applicantData.thirdParties.length > 0 &&
    (applicantData.thirdParties[0].name !== vaultMember.thirdParties[0].name ||
      applicantData.thirdParties[0].relationship !==
        vaultMember.thirdParties[0].relationship)
  ) {
    result = false;
  }

  console.log("isThirdPartyDataEqual", result);
  return result;
};

export const isPepDataEqual = (applicantData, vaultMember) => {
  let result = true;

  if (applicantData.thirdParties && !vaultMember.peps) {
    console.log("pep 1");
    result = false;
  } else if (!applicantData.peps && vaultMember.peps) {
    console.log("pep 2");
    result = false;
  } else if (applicantData.peps.length !== vaultMember.peps.length) {
    console.log("pep 3");
    result = false;
  } else if (
    applicantData.peps.length > 0 &&
    (applicantData.peps[0].fundsSource !== vaultMember.peps[0].fundsSource ||
      applicantData.peps[0].hio !== vaultMember.peps[0].hio ||
      applicantData.peps[0].jurisdiction !== vaultMember.peps[0].jurisdiction ||
      applicantData.peps[0].jurisdictionType !==
        vaultMember.peps[0].jurisdictionType ||
      applicantData.peps[0].name !== vaultMember.peps[0].name ||
      applicantData.peps[0].officeHeld !== vaultMember.peps[0].officeHeld ||
      applicantData.peps[0].relationship !== vaultMember.peps[0].relationship)
  ) {
    console.log("pep 4");
    result = false;
  }

  console.log("isPepDataEqual", result);
  return result;
};

export const businessDocumentList = (finInfo, activeProduct, applicantData) => {
  const result = [];
  const temp = finInfo?.config?.businessTypes
    .find((x) => x.templateId === activeProduct?.templateId)
    ?.documents?.map((list, idx) => {
      if (idx === 0) {
        if (
          applicantData?.applicantComments?.indexOf(
            "Has Master Business License: Yes"
          ) > -1
        ) {
          result.push(list.title);
        } else if (
          applicantData?.applicantComments?.indexOf(
            "Has Certificate of Incorporation: Yes"
          ) > -1
        ) {
          result.push(list.title);
        }
      } else if (list.title === "Trade Name") {
        if (applicantData?.business?.tradeName) {
          result.push(list.title);
        }
      } else {
        result.push(list.title);
      }
    });

  return result;
};

export const checkVaultMemberData = (
  applicantData,
  doGetVaultMember,
  doUpdateVaultMember,
  finInfo
) => {
  console.log("checkVaultMemberData", applicantData);
  const refreshMemberVault = sessionStorage.getItem(S_MEMBER_VAULT_UPDATE);
  doGetVaultMember((res) => {
    console.log(res);
    if (res && res.status === HTTP_STATUS.OK) {
      if (
        !res.data?.name &&
        !isNewMember(applicantData) &&
        Array.isArray(finInfo.kycAllowedEdit)
      ) {
        doUpdateVaultMember(
          {
            name: applicantData.member.name,
            middleName: applicantData.member.middleName,
            surname: applicantData.member.surname,
            dob: applicantData.member.dob,
            sin: applicantData.member.sin,
            regulatoryFields: applicantData.regulatory.regulatoryFields,
            fatcaStatus: applicantData.regulatory.fatcaStatus,
            fatcaType: applicantData.regulatory.fatcaType,
            ssn: applicantData.regulatory.ssn,
            memberFundsSource: applicantData.regulatory.memberFundsSource,
            memberHasThirdParty: applicantData.regulatory.memberHasThirdParty,
            memberIsHio: applicantData.regulatory.memberIsHio,
            memberIsPedp: applicantData.regulatory.memberIsPedp,
            memberIsPefp: applicantData.regulatory.memberIsPefp,
            memberJurisdiction: applicantData.regulatory.memberJurisdiction,
            memberOfficeHeld: applicantData.regulatory.memberOfficeHeld,
            thirdParties: applicantData.thirdParties,
            peps: applicantData.peps,
            pep: applicantData.member.pep,
          },
          (updateRes) => console.log(updateRes)
        );
      } else if (!Array.isArray(finInfo.kycAllowedEdit) && res.data?.name) {
        doUpdateVaultMember({}, (updateRes) => console.log(updateRes));
      }
    }
  });
  sessionStorage.removeItem(S_MEMBER_VAULT_UPDATE);
};

export const getJurisdictionType = (jurisdiction, finInfo) => {
  console.log(
    "getJurisdictionType",
    jurisdiction,
    finInfo?.config?.businessJurisdiction
  );
  let result = null;
  const j = finInfo?.config?.businessJurisdiction.find(
    (x) => x.code === jurisdiction
  );
  console.log(j);
  if (j) {
    result = j.type;
  }

  return result;
};

export const getNextStepAfterKYC = (processTemplate) => {
  let result = ROUTES.COMPLIANCE;
  const empIndex = processTemplate.flow.findIndex(
    (s) => s.componentName === AppConstants.APPLICATIONSTEP.EMPLOYMENT
  );
  const taxIndex = processTemplate.flow.findIndex(
    (s) => s.componentName === AppConstants.APPLICATIONSTEP.TAX_INFO
  );
  const complianceIndex = processTemplate.flow.findIndex(
    (s) => s.componentName === AppConstants.APPLICATIONSTEP.COMPLIANCE
  );
  console.log("indexs", empIndex, taxIndex, complianceIndex);
  if (empIndex > -1) {
    if (taxIndex > -1 && taxIndex < empIndex) {
      result = ROUTES.TAX_INFO;
    } else if (complianceIndex > -1 && complianceIndex < empIndex) {
      result = ROUTES.COMPLIANCE;
    } else {
      result = ROUTES.EMPLOYMENT_AND_INCOME;
    }
  } else if (taxIndex > -1) {
    if (complianceIndex > -1 && complianceIndex < taxIndex) {
      result = ROUTES.COMPLIANCE;
    } else {
      result = ROUTES.TAX_INFO;
    }
  }

  return result;
};

export const cleanAnswers = (answerData) => {
  console.log("cleanAnswers", answerData);
  if (Array.isArray(answerData)) {
    console.log("clean answers", answerData);
    return answerData.filter(
      (x) =>
        (x.hasOwnProperty("checked") && x.checked) ||
        !x.hasOwnProperty("checked")
    );
  }
  return answerData;
};

export const getFingerPrint = () => {
  let result = sessionStorage.getItem(S_INSTNT_FINGER_PRINT);

  if (!result) {
    const fingerPrintTxt = document.getElementById("fingerprint_txt");
    if (fingerPrintTxt?.value) {
      sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerPrintTxt?.value);
      result = fingerPrintTxt.value;
    } else if (window.instnt?.fingerprint_txt) {
      result = window.instnt.fingerprint_txt;
      sessionStorage.setItem(S_INSTNT_FINGER_PRINT, result);
    }
  }

  return result;
};
